
import Vue from "vue";

interface IData {
  importData: any[];
}

export default Vue.extend({
  name: "upload-fields-mapper",
  data(): IData {
    return {
      importData: []
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    disabledOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    matchedPropertyChanged(payload: any): void {
      this.importData[payload.index].matched =
        payload.newValue === "" || payload.newValue.length === 0 ? false : true;
      this.$emit("matchedPropertyChanged", payload);
    }
  },
  mounted() {
    this.importData = this.data.filter((row: any) => !row.matched);
  }
});
