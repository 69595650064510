
import Vue from "vue";
import { importMapState } from "@/store/modules/import";

export default Vue.extend({
  methods: {
    summaryTableData(): any[] {
      if (!this.editing || !this.editing.result) {
        return [];
      }
      const {
        totalRowsProcessed,
        totalRowsWithErrors,
        totalRowsAlreadyExisting
      } = this.editing.result;
      return [
        { title: "Total Rows Processed", outcome: totalRowsProcessed },
        {
          title: "Total Rows Imported",
          outcome: this.editing.result.createdIds.length
        },
        { title: "Total Rows With Errors", outcome: totalRowsWithErrors },
        { title: "Total Rows Existing", outcome: totalRowsAlreadyExisting }
      ];
    },
    summaryErrorsTableData(): any[] {
      if (!this.editing || !this.editing.result) {
        return [];
      }
      return this.editing.result.errors.map((errorMessage: string) => ({
        errorMessage
      }));
    },
    viewImportedItems() {
      if (this.editing) {
        const userPath = this.$isCurrentUserAdmin ? "/admin/users" : "/users";
        const mortgagePath = this.$isCurrentUserAdmin
          ? "/admin/mortgages"
          : "/mortgages";
        switch (this.editing.type) {
          case "users":
            return this.$router.push({
              path: userPath,
              query: {
                _id__in: this.editing.result.createdIds,
                createMethod: "import"
              }
            });
          case "mortgages":
            return this.$router.push({
              path: mortgagePath,
              query: {
                _id__in: this.editing.result.createdIds
              }
            });
          default:
            console.error("Unsupported upload type", this.editing.type);
        }
      }
    }
  },
  computed: {
    ...importMapState(["editing"])
  }
});
