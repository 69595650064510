import { ImportTypeArr } from "@/store/modules/import/types";
import { format } from "date-fns";
import { capitalize } from "lodash";

export const tableColumns = {
  name: {
    formatDataKey: "Name",
    formatDataValue: (arg: string) => arg,
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 0,
    colWidth: 600
  },
  type: {
    formatDataKey: "Type",
    formatDataValue: (arg: string) => capitalize(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    simpleFilter: ImportTypeArr.map((type: string) => ({
      label: capitalize(type),
      value: type
    })),
    position: 1
  },
  status: {
    formatDataKey: "Status",
    formatDataValue: (arg: string) => capitalize(arg),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    position: 3
  },
  createdOn: {
    formatDataKey: "Created",
    formatDataValue: (arg: string) => format(new Date(arg), "yyyy-M-d"),
    allowInAdvancedFilter: true,
    allowInTableOptions: true,
    visibleByDefault: true,
    allowSort: true,
    allowInSimpleFilter: true,
    position: 4
  }
};
