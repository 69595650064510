
import Vue from "vue";
import { tableColumns } from "./tableColumns";
import { ITableWrapperColumns } from "@/types";
import TableOperations from "./tableOperations.vue";
import {
  importMapActions,
  importMapGetters,
  importMapState
} from "@/store/modules/import";
import { capitalize } from "lodash";
import { ImportStatusArr } from "@/store/modules/import/types";

interface IViewImportsData {
  tableActions: any[];
  tableColumns: ITableWrapperColumns;
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  companyId: string;
  filters: any[];
  importId: string;
  loadingText: string;
}
export default Vue.extend({
  name: "ImportListView",
  components: {
    ImportsUploader: () => import("./ImportsUploader.vue")
  },
  data(): IViewImportsData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      tableColumns: tableColumns,
      loading: false,
      companyId: "",
      importId: "",
      filters: [
        {
          field: "status",
          title: "Status",
          options: ImportStatusArr.map((status: string) => ({
            label: capitalize(status),
            value: status
          }))
        },
        {
          field: "createdOn",
          title: "Created On",
          options: [],
          isDate: true
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...importMapActions(["getImports", "deleteImport", "updateImport"]),
    async fetchPageData(query: any = {}): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Fetching data...";
        this.$emit("scrollTop");
        await this.getImports({ query });
      } catch (e) {
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      if (this.$isCurrentUserAdmin) {
        this.$router.push(`/admin/imports/${_id}`);
      } else {
        this.$router.push(`/imports/${_id}`);
      }
    },
    async createCallback(): Promise<any> {
      this.$modal.show("uploadImportFileModal");
    },
    getOperationsTemplate(): any {
      return TableOperations;
    },
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "view":
          this.viewCallback(data);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneImport(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting item. Please wait...";
        await this.updateImport({
          id: this.importId,
          update: { deleted: true }
        });
        this.$modal.hide("importsDeleteModal");
        this.fetchPageData({});
        this.$appNotifySuccess("Import Successfully Deleted");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async deleteCallback(id: any): Promise<void> {
      try {
        this.importId = id;
        this.$modal.show("importsDeleteModal");
      } catch (error) {
        if (error !== "cancel") {
          console.error(error.message);
        }
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...importMapGetters(["getTotalRecords"]),
    ...importMapState(["imports", "links", "makingApiRequest"]),
    tableRowActions(): {
      label: string;
      value: string;
      faIcon: string;
      class?: string;
    }[] {
      let actions: any[] = [{ label: "View", value: "view", faIcon: "eye" }];
      if (this.$isCurrentUserAdmin) {
        actions.push({
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        });
      }
      return actions;
    },
    primaryButton(): Record<string, string> {
      return this.$isCurrentUserAdmin || this.$isCurrentUserDataEntry
        ? {
            text: "Import Data",
            key: "create"
          }
        : {};
    }
  }
});
