
import UploadFieldsMapper from "./UploadFieldsMapper.vue";
import ImportReport from "./ImportReport.vue";
import * as types from "@/store/mutation-types";
import { validateFields, objectDifference } from "@/helpers";

import Vue from "vue";
import {
  importMapActions,
  importMapGetters,
  importMapMutations,
  importMapState
} from "@/store/modules/import";
import { IImportHeadersMetadata } from "@/store/modules/import/types";
import { omit } from "lodash";

export interface IHeadersMeta {
  matched: boolean;
  header: string;
  property: string;
  preview: string[];
  defaultValue?: string;
}

export interface FieldOptions {
  label: string;
  value: string;
  required: boolean;
}

export interface IImportData {
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  errorMsg: string;
  importData: any[];
  unmatchedIndex: number;
}

export default Vue.extend({
  components: { UploadFieldsMapper, ImportReport },
  data(): IImportData {
    return {
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The import you are looking for cannot be found.",
      errorMsg: "",
      importData: [],
      unmatchedIndex: 0
    };
  },
  created() {
    this.getImport(this.importId)
      .then((importFetched: any) => {
        this.setImportEdit(importFetched);
        this.setImportDataForEdit();
      })
      .catch(error => {
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      });
  },
  methods: {
    ...importMapActions(["updateImport", "getImport", "completeImport"]), //"completeImport" need this
    ...importMapMutations({
      setImportEdit: types.SET_EDIT,
      editImportField: types.SET_EDIT_FIELD
    }),
    setImportDataForEdit() {
      this.unmatchedIndex = this.editing.importHeadersMetadata.findIndex(
        (row: IImportHeadersMetadata) => !row.matched
      );
    },
    matchedPropertyChangedHandler(payload: any): void {
      let { index, newValue } = payload;
      index = index + this.unmatchedIndex;
      const isMatched = newValue === "" || newValue.length === 0 ? false : true;
      this.editImportField({
        key: `importHeadersMetadata[${index}].property` as any,
        value: newValue
      });
      this.editImportField({
        key: `importHeadersMetadata[${index}].matched` as any,
        value: isMatched
      });
    },
    async completeImportData(): Promise<void> {
      try {
        await this.completeImport(this.importId);
        this.$appNotify({
          title: "Import Request Submitted",
          type: "success",
          message:
            "Your import request has been completed. An email will be sent to you once we finish processing your import"
        });
        if (this.$isCurrentUserAdmin) {
          this.$router.push("/admin/imports").catch(() => {});
        } else {
          this.$router.push("/imports").catch(() => {});
        }
      } catch (error) {
        this.errorMsg = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    async updateImportData(): Promise<void> {
      try {
        const update = { ...this.updatedFields };
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (!isValid) {
          this.errorMsg =
            "Sorry. You've invalid data in forms. Correct them and try again.";
          return;
        } else {
          await this.updateImport({ id: this.importId, update });
          this.$appNotifySuccess("Import has been updated successfully");
        }
      } catch (error) {
        this.errorMsg = error.message;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...importMapState(["editing", "makingApiRequest"]),
    ...importMapGetters(["getImportById"]),
    original(): any {
      return this.getImportById(this.importId);
    },
    requiredFields(): any[] {
      return this.editing.propertyFieldOptions
        .filter((option: FieldOptions) => option.required)
        .map((option: FieldOptions) => option.value);
    },
    selectedProperties(): string[] {
      const selectedProps = this.editing.importHeadersMetadata
        .filter(
          (row: IImportHeadersMetadata) => row.matched && row.property.length
        )
        .map((row: IImportHeadersMetadata) => row.property);
      this.propertyFieldOptions.forEach((entry: any) => {
        if (entry.defaultValue) {
          selectedProps.push(entry.value);
        }
      });
      return selectedProps;
    },
    hasMatchedAllRequiredFields(): boolean {
      if (this.editing.type === "profile") {
        return true; //just let server do the validation
      }
      for (const field of this.requiredFields) {
        if (!this.selectedProperties.includes(field)) {
          return false;
        }
      }
      return true;
    },
    noUnmatchedColumns(): boolean {
      const unmatched = this.original?.importHeadersMetadata?.findIndex(
        (row: IImportHeadersMetadata) => !row.matched
      );
      if (unmatched === -1) {
        return true;
      } else {
        return false;
      }
    },
    importId: function(): string {
      return this.$route.params.importId;
    },
    propertyFieldOptions(): any[] {
      return this.editing.propertyFieldOptions
        ? this.editing.propertyFieldOptions
        : [];
    },
    updatedFields(): any {
      const toIgnore = ["modifiedOn", "createdOn"];
      const origional = this.getImportById(this.importId);
      const edited = this.editing;
      return omit(
        edited && origional ? objectDifference(edited, origional) : {},
        toIgnore
      );
    }
  }
});
